import React, { FC, useMemo } from 'react';

import Container from 'layout/Container';
import ArticleBody from 'components/article/ArticleBody';
import ScrollButton from 'components/elements/ScrollButton';
import UpdateDate from 'components/elements/UpdateDate';

import { handleLdsBodyNavigation } from './helpers';
import { IPropsLdsPageBody } from './models';

import './LdsBody.scss';

const LdsBody: FC<IPropsLdsPageBody> = ({ ldsManualBody, updateDateText, updateDate, lang }) => {
  const ldsSecondaryNavigation = [...ldsManualBody];
  const ldsBodyNavigation = useMemo(
    () => handleLdsBodyNavigation(ldsSecondaryNavigation),
    [ldsManualBody]
  );

  return (
    <Container data-testid="LdsBody" className="lds-page__container">
      {updateDateText?.length ? (
        <UpdateDate updateDateText={updateDateText} updateDate={updateDate} lang={lang} />
      ) : null}
      {ldsBodyNavigation?.length ? (
        <ul className="lds-page__navigation">
          {ldsBodyNavigation.map((item) => (
            <li key={item.navigationName}>
              <ScrollButton
                navigationName={item.navigationName}
                scrollOffset={20}
                link={item.navigationName}
              />
            </li>
          ))}
        </ul>
      ) : null}
      {ldsManualBody ? <ArticleBody articleBody={ldsManualBody} /> : null}
    </Container>
  );
};

export default LdsBody;
