import React, { FC } from 'react';

import Typography from 'components/elements/Typography';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';

import { formatDate } from './helpers';
import { IPropsUpdateDate } from './models';

import './UpdateDate.scss';

const UpdateDate: FC<IPropsUpdateDate> = ({ updateDateText, updateDate, lang }) => (
  <div data-testid="UpdateDate" className="update-date">
    <Typography data={updateDateText?.[0]?.updateDateText} />
    <DangerouslySetInnerHtml html={formatDate(updateDate, lang)} />
  </div>
);

export default UpdateDate;
