const options: {
  year: 'numeric' | '2-digit';
  month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day: 'numeric' | '2-digit';
} = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const formatter = (lang) => new Intl.DateTimeFormat(lang, options);

export const formatDate = (updateDate, lang) => {
  const date = new Date(updateDate);

  return formatter(lang).format(date);
};
